import MainHeader from '../../components/mainHeader/Index.vue'
import MainFooter from '../../components/mainFooter/Index.vue'
import Dict from '../../components/dict/Index.vue'
import {queryProfiles,getTalentRoles,getTalentLocations} from '@/api/talent'
import config from "@/config";
import { getWeb3, _getGasPrice, _checkApproval2 } from '../../utils/web3';
import BigNumber from "bignumber.js";
import Pagination from '@/components/pagination/Index.vue'
import LoadMore from '@/components/loadMore/Index.vue'
import { Icon } from '@iconify/vue2'
export default {
  components: { MainHeader, MainFooter, Dict, Pagination, LoadMore, Icon },
  data(){
    return{
      cvPrice:1,
      cvPriceInit:false,
      currentAccount: null,
      topics: [{"name":"主题1","value":"topic1"}],
      roles: [],
      locations: [],
      talents:[],
      queryParams:{pageSize:5,pageNo:1},
      total:0,
      iscompleted:false,
      listLoading:false,
      selectRoleName: null,
      selectLocationName: null,
      balance:null,
      createInit:true,
      balanceInit:false
    }
  },
  filters:{
    formatLookingFor(param){
      if(param)
        return param.join('/')
      return ''
    }
  },
  created(){ 
    console.log(this.cvPriceInit)
    this.initCvPrice()
    this.initDropdown()
    this.queryProfiles()
    
  },
  watch: {
    async currentAccount(newVal, oldVal){
      console.log(this.createInit)
      if(this.createInit){
        this.createInit = false
        return;
      }
      if(newVal != oldVal){
        if(newVal != null)
        {
          this.balanceInit=false
          await this.queryBalance()
        }else{
          this.balance = null
        }
        this.iscompleted=false
        this.talents = []
        this.$refs["talentLoadMore"].pullDown()
      }
    }
  },
  methods:{
    checkChinese(val) {
        // 正则匹配非中英文及数字的字符
        let reg = /[\u4e00-\u9fa5]/;
        let n = reg.test(val);
        if ( n ) {
            return false
        }
        return true
    },
    async queryBalance(){
      const that = this
      const web3 = await getWeb3()
      if(that.currentAccount != null)
      {
        const connextERCContract = new web3.eth.Contract(config.connextErc20ABI, config.connextErc20Address)
        const balance = await connextERCContract.methods.balanceOf(that.currentAccount).call()
        that.balance = new BigNumber(balance)
        .dividedBy(new BigNumber(10).pow(18))
        .toFixed(0, BigNumber.ROUND_DOWN)
      }

      that.balanceInit = true
    },
    async initCvPrice(){
      const that = this
      const web3 = await getWeb3()
      const connextAppContract = new web3.eth.Contract(config.connextAppABI, config.connextAppAddress)
      const cvPrice = await connextAppContract.methods.cvPrice().call()
      console.log(cvPrice)
      that.cvPrice = new BigNumber(cvPrice)
      .dividedBy(new BigNumber(10).pow(18))
      .toFixed(0, BigNumber.ROUND_DOWN)
      that.cvPriceInit = true
    },
    async unlock(talentId,talentToken,event){
      const target = event.currentTarget
      if(event.currentTarget.disabled)
        return
      event.currentTarget.disabled=true
      const loading = this.$loading({
        target: event.currentTarget,
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      if(this.currentAccount == null){
        this.$message({
          showClose: true,
          message: 'Please connect wallet first!',
          type: 'error'
        })
        event.currentTarget.disabled=false
        loading.close()
        return
      }
      const that = this
      const web3 = await getWeb3()
      const connextAppContract = new web3.eth.Contract(config.connextAppABI, config.connextAppAddress)

      let amountToSend = await connextAppContract.methods.cvPrice().call()
      console.log(amountToSend)
      
      let checkResult = await _checkApproval2(this.currentAccount, talentToken, amountToSend);
      if(!checkResult){
        target.disabled=false
        loading.close();
        return;
      }
      connextAppContract.methods.purchaseCV(talentToken, amountToSend).send({ from: this.currentAccount, gasPrice: web3.utils.toWei(await _getGasPrice(), 'gwei')})
      .on('transactionHash', function(hash){
        console.log(hash);
      })
      .on('confirmation', function(confirmationNumber, receipt){
        console.log(receipt);
        console.log(confirmationNumber)
        if(confirmationNumber ===1)
          that.$router.push({name:'talentDetail',query:{talentId:talentId}})
      })
      .on('receipt', function(receipt){
        console.log(receipt);
      })
      .on('error', function(error) {
        target.disabled=false
        loading.close();
        // that.$message({
        //   showClose: true,
        //   message: error,
        //   type: 'error'
        // })
        console.log(error)
      })
      
    },
    async viewDetail(talentId){
      this.$router.push({name:'talentDetail',query:{talentId:talentId}})
    },
    changPages(page){
      this.listLoading = true
      this.queryParams.pageNo = page
      this.queryProfiles()
    },
    queryProfiles(){
      const that = this
      queryProfiles(that.queryParams).then(async(resp) => {
        // that.talents = resp.data.list
        this.createInit = false
        if(resp.data.list.length == 0){
          that.iscompleted = true
          that.listLoading = false
          return
        }
        const length = that.talents.length
        that.talents.splice(that.talents.length,0,...resp.data.list);
        that.total = resp.data.total
        let tempTalents = new Array()
        let tempTalentIndex = new Array()
        for(let i=length-1;i<that.talents.length;i++){
          let item = that.talents[i]
          if(!item)
            continue
          const hasPurchasedCV = await that.checkHasPurchasedCV(item.cnxtToken)
          if(hasPurchasedCV)
          {
            const temp = Object.assign({}, item, { hasPurchasedCV: hasPurchasedCV })
            tempTalents.push(temp)
            tempTalentIndex.push(i)
            that.talents.splice(i,1,temp)
          }
        }

        that.listLoading = false

      })
    },
    async checkHasPurchasedCV(talentToken){
      if(this.currentAccount == null)
        return false
      const web3 = await getWeb3()
      const connextAppContract = new web3.eth.Contract(config.connextAppABI, config.connextAppAddress)
      const hasPurchasedCVResult = await connextAppContract.methods.hasPurchasedCV(this.currentAccount,talentToken).call()

      return hasPurchasedCVResult
    },
    initDropdown(){
      const that = this
      getTalentRoles().then((resp)=>{
        that.roles = resp.data
      })
      getTalentLocations().then((resp)=>{
        that.locations = resp.data
      })
    },
    handleSelectRole(role){
      this.iscompleted=false
      this.queryParams.pageNo=1
      this.queryParams.pageSize=5
      this.queryParams.role = role.value
      this.selectRoleName = role.name
      this.talents = []
      this.$refs["talentLoadMore"].pullDown();
    },
    handleSelectLocation(location){
      this.iscompleted=false
      this.queryParams.pageNo=1
      this.queryParams.pageSize=5
      this.queryParams.location = location.value
      this.selectLocationName = location.name
      this.talents = []
      this.$refs["talentLoadMore"].pullDown();
    }
  }
};